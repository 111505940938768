<template>
  <div class="hello">
    <h1 class="h1">XD FAM</h1>
    <div class="logo-container">
      <img src="../assets/img/logo.png" alt="Logo" class="logo" />
    </div>
    <h2 class="h2">is coming</h2>
  </div>
</template>

<script>
import '../assets/fonts.css';
export default {
  name: 'HelloWorld',
}
</script>

<style scoped>
.hello {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh !important;
  text-align: center;
}

.logo-container {
  position: relative;
  width: 10rem;
  height: 10rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.logo {
  width: auto !important;
  height: 10rem !important;

}

.h1 {
  font-size: 3rem !important;
  margin: 0 !important;
}

.h2 {
  font-size: 1.5rem !important;
  margin: 0 !important;
}
</style>
