<template>
  <HelloWorld />
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
html, body, #app {
  height: 100vh;
  margin: 0;
  padding: 0;
}

#app {
  font-family: 'CustomFont', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: url('./assets/img/background.png') no-repeat center center !important;
  background-size: cover !important;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* filter: blur(5px); */
}

* {
  color: #fff !important;
  background: transparent !important;
}

body {
  padding: 0 !important;
  margin: 0 !important;
}
</style>
